<template>
  <base-section id="mobile">
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <base-section-heading
            align="left"
            subtitle="Y-Squared"
            tag="h2"
            title="Our Services"
          >
            We offer full stack development Services. Clientside UI, API's, Serverside Business Logic, Database's, Dev Op's & SEO. Our team also offers Graphic Design packages, Cosultaion & general purpose IT Services.
          </base-section-heading>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-row>
            <v-col
              v-for="([icon, title], i) in features"
              :key="i"
              cols="12"
              md="6"
            >
              <div class="d-flex align-center">
                <base-avatar
                  data-aos="zoom-in"
                  :icon="icon"
                  class="mr-6 elevation-3"
                  color="primary"
                  dark
                  outlined
                />

                <v-responsive max-width="112">
                  <base-subtitle
                    :title="title"
                    class="text--primary"
                  />
                </v-responsive>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'ServiceSection',

    data: () => ({
      features: [
        ['mdi-web', 'Web Apps'],
        ['mdi-cellphone', 'Mobile Apps'],
        ['mdi-desktop-mac-dashboard', 'Web Design'],
        ['mdi-lead-pencil', 'Graphic Design'],
        ['mdi-cloud-outline', 'Dev Ops'],
        ['mdi-card-search-outline', 'SEO'],
        ['mdi-human-greeting', 'Tech Consultation'],
        ['mdi-account-cog-outline', 'IT Services'],
      ],
    }),
  }
</script>
